<template>
  <div class="media-gallery1">
    <div class="media-gallery-carousel">
      <carousel
        v-bind="isMobile ? sliderConfigMobile : sliderConfig"
        ref="carousel"
        :speed="carouselTransitionSpeed"
        @pageChange="pageChange"
      >
        <slide
          v-for="(images, index) in gallery"
          :key="images.src"
        >
          <div
            class="product-image-container bg-cl-primary"
            :class="{'video-container w-100 h-100 flex relative': images.video}"
          >
            <product-image
              v-if="hideImageAtIndex === index"
              @click="openOverlay"
              class="pointer image"
              :image="images"
              :alt="productName | htmlDecode"
            />
            <product-video
              v-if="images.video && (index === currentPage)"
              v-bind="images.video"
              :index="index"
              @video-started="onVideoStarted"
            />
          </div>
        </slide>
      </carousel>
      <div v-show="gallery && gallery.length" class="zoom-in" @click="openOverlay">
        <img :src="'/assets/svg/zoom_in.svg'" class="pointer p15" width="20px" height="20px" alt="zoom-in">
      </div>
    </div>
    <p v-show="gallery && gallery.length" class="m0 pr15 pt5 h6 align-right">
      zdjęcie poglądowe
    </p>
    <ul @mouseenter="loadMoreImage()" id="image-list" class="media-gallery1__thumbs m0 p0">
      <li class="media-gallery1__thumb" v-for="(images, key) in gallery" :key="images.src">
        <span v-if="showImageList(key) || flagList" class="bg-cl-secondary block">
          <img
            key="lowQualityImage"
            width="60px"
            height="60px"
            v-lazy="images.loading"
            ref="images"
            @click="navigate(key)"
            :alt="productName | htmlDecode"
          >
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { MobileDetected } from './mobileDetected.ts'
import config from 'config'
import ProductImage from './ProductImage'
import { Carousel, Slide } from 'vue-carousel'
import ProductVideo from './ProductVideo'
import reduce from 'lodash-es/reduce'
import map from 'lodash-es/map'

export default {
  name: 'ProductGalleryCarousel',
  components: {
    Carousel,
    Slide,
    ProductImage,
    ProductVideo
  },
  props: {
    gallery: {
      type: Array,
      required: true
    },
    productName: {
      type: String,
      default: ''
    },
    configuration: {
      type: Object,
      required: true
    }
  },
  mixins: [MobileDetected],
  data () {
    return {
      sliderConfigMobile: {
        perPage: 1,
        paginationColor: '#bdbdbd',
        paginationActiveColor: '#FF8100',
        paginationPosition: 'bottom-overlay',
        paginationEnabled: false,
        infiniteLoop: true,
        centerMode: true,
        touchDrag: true,
        scrollPerPage: true
      },
      sliderConfig: {
        perPage: 1,
        mouseDrag: false,
        navigationEnabled: false,
        paginationEnabled: false,
        navigationNextLabel: "<i class='fs-big p15 cl-bg-tertiary pointer'>&#62;</i>",
        navigationPrevLabel: "<i class='fs-big p15 cl-bg-tertiary pointer'>&#60;</i>"
      },
      carouselTransition: true,
      carouselTransitionSpeed: 0,
      currentColor: 0,
      currentPage: 0,
      hideImageAtIndex: 0,
      flagList: false
    }
  },
  beforeMount () {
    this.$bus.$on('filter-changed-product', this.selectVariant)
    this.$bus.$on('product-after-load', this.selectVariant)
  },
  mounted () {
    this.selectVariant()
    if (this.configuration.color) {
      const { color } = this.configuration
      this.currentColor = color.id
    }
    this.$emit('loaded')
  },
  beforeDestroy () {
    this.$bus.$off('filter-changed-product', this.selectVariant)
    this.$bus.$off('product-after-load', this.selectVariant)
  },
  computed: {

  },
  methods: {
    loadMoreImage () {
      this.flagList = true
    },
    showImageList (index) {
      // const container = 500
      // const item = 80
      // let perPage = Math.ceil(container / 80)
      // let length = this.gallery.length
      let perPage = 6
      return index + 1 <= perPage
    },
    navigate (index) {
      if (this.$refs.carousel) {
        this.$refs.carousel.goToPage(index)
      }
    },
    selectVariant () {
      if (config.products.gallery.mergeConfigurableChildren) {
        const option = reduce(map(this.configuration, 'attribute_code'), (result, attribute) => {
          result[attribute] = this.configuration[attribute].id
          return result
        }, {})
        if (option) {
          let index = this.gallery.findIndex(
            obj => obj.id && Object.entries(obj.id).toString() === Object.entries(option).toString(), option)
          if (index < 0) index = this.gallery.findIndex(obj => obj.id && obj.id.color === option.color)
          this.navigate(index)
        }
      }

      this.$emit('close')
    },
    openOverlay () {
      const currentSlide = this.$refs.carousel.currentPage
      this.$emit('toggle', currentSlide)
    },
    switchCarouselSpeed () {
      const { color } = this.configuration
      if (color && this.currentColor !== color.id) {
        this.currentColor = color.id
        this.carouselTransitionSpeed = 0
      } else {
        this.carouselTransitionSpeed = 500
      }
    },
    pageChange (index) {
      this.switchCarouselSpeed()

      this.currentPage = index
      this.hideImageAtIndex = index
    },
    onVideoStarted (index) {
      this.hideImageAtIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.media-gallery1__thumbs {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 16px;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #7DBA10;
    border-radius: 16px;
    width: 6px;
  }
}
.media-gallery-carousel {
  position: relative;
  text-align: center;
  height: 100%;
}
.zoom-in {
  position: absolute;
  bottom: 0;
  right: 0;
}
.product-image-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 600px;
  height: 100%;
}
.product-image {
  width: 100%;
  height: auto;
}
img {
  opacity: 1;
  mix-blend-mode: multiply;
  vertical-align: top;
  &:hover {
    opacity: 0.9;
  }
}
img[lazy=error] {
  width: 100%;
}
img[lazy=loading] {
  width: 100%;
}
img[lazy=loaded] {
  -webkit-animation: none;
  animation: none;
}

.video-container {
  align-items: center;
  justify-content: center;
}
.media-gallery1 {
  // display: flex;
  // flex-wrap: wrap;

  &__thumbs {
    list-style: none;
    width: 100%;
    padding: 5px 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    overflow: auto;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__thumb {
    width: 60px;
    height: 60px;
    margin: 10px 10px;
    cursor: pointer;
    display: inline-flex;

    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      mix-blend-mode: multiply;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>

<style lang="scss">
.media-gallery-carousel,
.media-zoom-carousel {
  .VueCarousel-pagination {
    position: absolute;
    .VueCarousel-dot-container {
      display: flex;
      .VueCarousel-dot {
        padding: 0px !important;
        margin: 0 !important;
        border: 0 !important;
        border-radius: 0 !important;
        width: 100% !important;
        height: 5px !important;
        outline: none !important;
        display: block;
      }
    }
  }
  .VueCarousel-navigation-button {
    margin: 0;
    transform: translateY(-50%) !important;
  }
  .VueCarousel-slide {
    backface-visibility: unset;
  }
  .VueCarousel-navigation {
    opacity: 0;
    &--disabled {
      opacity: 0.3;
    }
  }
  .VueCarousel-dot {
    padding: 0px !important;
    button {
      border: 2px solid #828282;
    }
  }
  &:hover {
    .VueCarousel-navigation {
      opacity: .9;
    }
    .VueCarousel-navigation-button {
      transition: opacity 3s;
      &:after {
        background-color: transparent;
      }
    }
  }
}
</style>
